import styled from 'styled-components';

export const HeaderBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    flex-direction: column;
    margin: 0 0 8vh 0;

    h1 {
        color: orange;
        font-size: 1.2rem;
        text-align: center;
    }

    img {
        width: 75%;
    }

    .content-50a, .content-50b {
        width: 100%;
    }

    .content-50a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1.5vh;
        margin-bottom: 1rem;

        h1 {
            font-size: 6vw;
        }

        h2 {
            color: white;
            font-size: 5vw;
        }

        a {
            color: #1aff1a;
            font-size: 5vw;

            &:hover {
                cursor: pointer;
            }
        }

        hr {
            width: 30%;
            border-radius: 50%;
        }

        p {
            font-size: 4vw;
            color: orange;
        }

        img {
            width: 50%;
            border-radius: 2px;
            margin-bottom: -2vh;
        }
    }

    @media (min-width: 768px) {
        img {
            width: 30%;
        }

        .content-50a {
            h2, a, p {
                font-size: 2vw;
            }

            h1 {
                font-size: 3.5vw;
            }
        }
    }
`

export const LogoWhatsImg = styled.img`
    z-index: 1000;
    position: fixed;
    scale: 0.23;
    left: 45vw;
    bottom: 3vh;
    border: none;

    transform: scale(1);
	animation: pulse 2s infinite;

    @keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}

    @media (min-width: 768px) {
        bottom: -53vh; 
        scale: 0.08; 
    }
`

export const FormLead = styled.form`

    border: 2px solid orange;
    display: flex;
    flex-direction: column;
    padding: 5vh 1vh;
    background-image: url('../../assets/imgs/BckForm.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1vh;
    position: relative;

    img {
        width: 35vw;
        background-color: black;
        border-radius: 1vw;    
    }

    h1, h2 {
        font-size: 4vw;
        border-top: 1px solid black;
        margin-top: 1vh;
        padding-top: 1vh;
        text-shadow: 5px 3px 10px rgba(0,0,0,0.36);
    }

    h1 {
        color: #021263;
        margin-bottom: 1vh;
    }

    input {
        width: 90%;
        text-align: center;
        font-size: 0.8rem;
        padding: 0.2rem 0;

        &::placeholder {
            text-align: center;   
        }

        &:focus {
            outline: none;
            font-size: 3.5vw;
            border: 1px solid green;
        }
    }

    label {
        font-size: 4vw;
        color: #ff5d00;
    }

    input {
        font-size: 2.7vw;
        border-radius: 6px;
    }

    button {
        margin-top: 1rem;
        background-color: green;
        border-radius: 0.6rem;
        padding: 0.3rem 1rem;
        color: white;
        font-weight: 700;
        text-shadow: 1px 1px 2px black;

        &:hover {
            cursor: pointer;
            background-color: #010826;
        }
    }

    @media (min-width: 768px) {
        margin: 0 auto;
        width: 50%;
        padding: 10vh 0;
        gap: 2vh;
        
        img {
            width: 20vw;
        }

        h1, h2 {
            font-size: 1.2vw;
        }

        input {
            width: 70%;
            text-align: center;
            padding: 0.2rem 0;
            font-size: 1vw;
            border-radius: 6px;

            &::placeholder {
                text-align: center;   
            }

            &:focus {
                outline: none;
                font-size: 1.2vw;
                border: 1px solid green;
            }
        }

        label {
            font-size: 1.3vw;
            color: #ff5d00;
        }

        button {
            margin-top: 3rem;
            background-color: green;
            border-radius: 0.6rem;
            padding: 0.3rem 1rem;
            color: white;
            font-weight: 700;
            text-shadow: 1px 1px 2px black;

            &:hover {
                cursor: pointer;
                background-color: #010826;
            }
        }
    }
`

export const FooterHeader = styled.div`
    width: 100%;
    background-color: white;
    margin-top: 2rem;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

`

export const UlFooter = styled.ul`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0.2rem 0;
    gap: 2vw;

    li {
        list-style-type: none;
    }

    li p {
        text-decoration: none;
        color: black;
        font-size: 3vw;
    }

    @media (min-width: 768px) {
        li p {
            font-size: 1vw;
        }
    }

`

export const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const IframeYT = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        width: 50%;
        height: 50%;
        margin: 0 auto;
    }
`

export const IframeContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;

    @media (min-width: 768px) {
        margin-bottom: -28%;
    }
`