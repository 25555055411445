import { useState } from "react";
import emailjs from '@emailjs/browser';
import { Wrapper } from "../../styles/Wrapper";
import { ContainerHeader, FooterHeader, FormLead, HeaderBody, IframeContainer, IframeYT, LogoWhatsImg, UlFooter } from "./styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=5551994599898&text=Ol%C3%A1.%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gratu%C3%ADta!';

    const whatsString = '(51) 99459-9898';

    const [name, setName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setEmail] = useState('');
    const [whats, setWhats] = useState('');

    const sendEmail = (e: any) => {
        e.preventDefault();

        if (name === '' || email === '' || whats === ''){
            alert('Por favor, preenche todos os campos ou fale conosco através do WhatsApp para maiores informações!');
            return
        }

        const templateParams = {
            from_name: name,
            from_lName: lName,
            email: email,
            whats: whats,
        }

        emailjs.send('service_i9tt9yx', 'template_aejoc9h', templateParams, '9qKP69-7rmWYiFeGV')
        .then((response) => {
            console.log('Email Enviado', response.status, response.text);
            alert('Inscrição enviada com sucesso!')
            setName('');
            setlName('');
            setEmail('');
            setWhats('');
        }, (err) => {
            console.log('Fail', err);
        })
    }

    return(
        <ContainerHeader>
            <Wrapper>
                <HeaderBody>
                    <img src="../../assets/imgs/Mcmvo.png" alt="Logo Minha Casa, Minha Vida" />

                    <div className="content-50a">
                        <p style={{ color: 'white', fontWeight: '700'}}>Assista ao vídeo e saiba mais!</p>
                        <IframeContainer>
                            <IframeYT
                                src="https://www.youtube.com/embed/7gVs2ecQ0hA?autoplay=0&color=red&controls=0&showinfo=0&modestbranding=1&iv_load_policy=3" 
                                title="YouTube video player" 
                                id="click-video" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
                                allowFullScreen>
                            </IframeYT>
                        </IframeContainer>
                        <p style={{ color: 'white', fontWeight: '700'}}>Inscrição no Whats ou Formulário no <br/> FINAL DA PÁGINA!</p>
                        <hr />
                        <h1>Minha Casa, <br /> Minha Vida</h1>
                        <hr />
                        <h2 style={{color: 'white'}}>A Maneira Mais RÁPIDA <br/> para ter o SEU IMÓVEL!</h2>
                        <hr />
                        <h2 style={{color: 'orange'}}>Imóveis de até R$350MIL</h2>                           
                        <hr />
                        <h2>Parcelas Menores<br />Que Alugueis!</h2>
                        <hr />
                        <h2 style={{color: 'orange'}}>Programa <br /> Minha Casa Minha Vida</h2>
                        <h2 style={{color: 'white', marginTop: '-0.5rem'}}>Em breve em:</h2>
                        <h2 style={{color: 'white', marginTop: '-0.5rem'}}>Cachoeirinha, Gravataí e Porto Alegre!</h2>
                        <hr />
                        <a href={whatsUrl} style={{textDecoration: 'none'}} id="click-whats">Inscrição Rápida <br/>no Whats ou Formulário</a>
                        <hr />
                        <a href={whatsUrl} style={{textDecoration: 'none', color: 'white'}} id="click-whats">{whatsString}</a>
                    </div>

                    <div className="content-50b">

                        <FormLead id="fullForm" onSubmit={sendEmail}>
                            <img src="../../assets/imgs/BodyImgNB.png" alt="Logo Parceria - Ampla Imóveis e Caixa Econômica" />
                            <h2 style={{color: '#ff5d00'}}>Inscrição Fácil - 2023</h2>
                            <h1>Minha Casa, Minha Vida</h1>
                            <label>Nome Completo:</label>
                            <input 
                                name="leadName"
                                onChange={(e) => setName(e.target.value)}
                                type="text" 
                                id="fname" 
                                placeholder="Nome"
                                value={name}
                            />
                            <input 
                                name="lName"
                                onChange={(e) => setlName(e.target.value)}
                                type="text" 
                                id="lname" 
                                placeholder="Sobrenome"
                                value={lName}
                            />
                            <label>Contato:</label>
                            <input 
                                name="leadEmail"
                                onChange={(e) => setEmail(e.target.value)}
                                type="email" 
                                id="amountLabel" 
                                placeholder="exemplo@exemplo.com"
                                value={email}
                            />
                            <input 
                                name="leadNum" 
                                onChange={(e) => setWhats(e.target.value)}
                                type="text" 
                                id="whatsNum" 
                                placeholder="(51) 9 9999-9999"
                                value={whats}
                            />
                            <button type="submit" id="click-form-submit">Inscrever-se!</button>
                        </FormLead>
                    </div>

                    <a href={whatsUrl} target="_blank" rel="noreferrer">
                        <LogoWhatsImg src="../assets/imgs/wpp-icon.png" alt="Logo WhatsApp" id="click-whats"/>
                    </a>
                    
                    
                </HeaderBody>
                
            </Wrapper>

            <FooterHeader>
                <Wrapper>
                    <UlFooter>
                        <FontAwesomeIcon icon={faLock}/>
                        <li><p>Site Seguro</p></li>
                    </UlFooter>
                </Wrapper>
            </FooterHeader>
        </ContainerHeader>
    )
}

export default Header;