import { useCookies } from "react-cookie";
import { BackOpacity, CookieContent } from "./styles";
import { useState } from "react";


const ControlCookies = () => {

    const [cookies, setCookies] = useCookies(["cookieConsent"]);
    const [elementVisible, setElementVisible] = useState(cookies.cookieConsent ? false : true);

    const acceptCookies = () => {
        if(!cookies.cookieConsent) {
            setCookies("cookieConsent", true, {path: "/", maxAge: 365 * 60 * 60 * 1000});      
            setElementVisible(!elementVisible);
        } 
    }

    return(
        <>
            {elementVisible ? (
                <>
                <CookieContent>
                    <div className="cookie-text">
                        <h2>O nosso site usa cookies</h2>
                        <p>Utilizamos cookies e outras tecnologias de medição para melhorar a sua experiência de navegação no nosso site, de forma a mostrar conteúdo personalizado, anúncios direcionados, analisar o tráfego do site e entender de onde vêm os visitantes.</p>                      
                    </div>
                    <div className="cookie-btns">
                        <button id="more-info"><a href="/privacidade">Mais Informações</a></button>
                        <button id="accept-cookies" onClick={acceptCookies}>Eu Aceito Me Inscrever</button>
                    </div>
                </CookieContent>  
                <BackOpacity/> 
                </>
            ) : null}
        </>
    )
}

export default ControlCookies;