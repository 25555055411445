import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carroussel from "../components/Carroussel";
import { FooterHeader, HeaderBody, LogoWhatsImg, UlFooter } from "../components/Header/styles";
import NavBar from "../components/NavBar";
import GlobalStyle from "../styles/Global.style";
import { Wrapper } from "../styles/Wrapper";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import ControlCookies from "../components/ControlCookies";

const Catalogo = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=555192770784&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gr%C3%A1tis!'

    return(
        <>
            <GlobalStyle/>
            <NavBar/>
            <Carroussel/>
            <FooterHeader>
                <Wrapper>
                    <UlFooter>
                        <FontAwesomeIcon icon={faLock}/>
                        <li><p>Site Seguro</p></li>
                    </UlFooter>
                </Wrapper>
                
   
            </FooterHeader>
            <HeaderBody>
                <ControlCookies/>
                <a href={whatsUrl} id="click-whats">
                        <LogoWhatsImg src="../assets/imgs/wpp-icon.png" alt="Logo WhatsApp" />
                </a>
            </HeaderBody>

        </>
    )
}

export default Catalogo;