import styled from "styled-components";

export const PrivDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    width: 70%;
    justify-content: center;
    align-items: center;

    p, ul li {
        color: white;
    }

    h2, h3, a {
        color: orange;
    }

    a {
        text-decoration: none;
    }
`