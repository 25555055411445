import { Wrapper } from "../../styles/Wrapper";
import { CarrousselWrapper, TitleCarsl, TitleCarsl2, TitleCarsl3, TitleDiv, TitleDiv2 } from "./styles";
import { EmblaCarousel } from "./EmblaCarousel";


const Carroussel: React.FC = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=555192770784&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gr%C3%A1tis!'

    return(
        <>
        <Wrapper>
            <TitleDiv>
                <TitleCarsl>Imóveis</TitleCarsl>
                <TitleCarsl2>- Minha Casa, Minha Vida -</TitleCarsl2>
            </TitleDiv>

        </Wrapper>

        <Wrapper>
            <CarrousselWrapper>
                <EmblaCarousel/>
            </CarrousselWrapper>
        </Wrapper>

        <Wrapper>
            <TitleDiv2>
                <TitleCarsl2>Algumas de nossas opções...</TitleCarsl2>
                <TitleCarsl2>Saiba mais no WhatsApp</TitleCarsl2>
                <a href={whatsUrl} id="click-whats"><TitleCarsl3>WhatsApp</TitleCarsl3></a>
                <a href={whatsUrl} id="click-whats" style={{color: '#00f01c'}}><span>(51)9 9277-0784</span></a>
            </TitleDiv2>
        </Wrapper>
        </>
    )
}

export default Carroussel;