import { Link } from "react-router-dom";
import { Wrapper } from '../../styles/Wrapper';
import { FullBck, LinksUl } from './styles';

const NavBar: React.FC = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=5551994599898&text=Ol%C3%A1.%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gratu%C3%ADta!'

    return (
        <FullBck>
            <Wrapper>
                <LinksUl>
                    <li><a href={whatsUrl} id="click-whats">Inscrição Fácil</a></li>
                    <li><Link to="/catalogo" id="click-catalogo">Catálogo</Link></li>
                </LinksUl>
            </Wrapper>
        </FullBck>
    )
}

export default NavBar;