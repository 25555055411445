import GlobalStyle from "../styles/Global.style";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import ControlCookies from "../components/ControlCookies";
import { HeaderBody } from "../components/Header/styles";

const Home = () => {
    return(
        <>  
            <GlobalStyle/>
            <NavBar/>
            <Header/>
            <HeaderBody>
                <ControlCookies/>
            </HeaderBody>
        </>
    )
}

export default Home;