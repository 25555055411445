import styled from "styled-components";

export const CarrousselWrapper = styled.div`
    width: 100%;
    height: auto;
    border: 1px solid orange;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin: 2vh 0 2vh 0;

    @media (min-width: 768px) {
        height: 80vh;
        object-fit: scale-down;
    }
`

export const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`
export const TitleDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1vh;
    margin: 1vh;

    a {
        color: green;
        text-decoration: none;
    }

    @media (min-width: 768px) {
        a {
            font-size: 2vw;
        }
    }
`

export const TitleCarsl = styled.h1`
    color: orange;
    font-size: 6vw;
    margin-top: 6vh;
    margin-bottom: 1vh;

    @media (min-width: 768px) {
        font-size: 4vw;
    }
`

export const TitleCarsl2 = styled.h2`
    color: white;
    font-size: 3vw;
    
    @media (min-width: 768px) {
        font-size: 2vw;
    }
`

export const TitleCarsl3 = styled.h3`
    color: #00f01c;
    font-size: 4vw;

    @media (min-width: 768px) {
        font-size: 2vw;
    }
`