import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

export const EmblaCarousel: React.FC = () => {
    const autoplayOptions = {
      delay: 4000,
      rootNode: (emblaRoot:any) => emblaRoot.parentElement,
    }

    const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay(autoplayOptions)])
  
    return (
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel00.jpg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel01.jpeg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel02.jpeg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel03.jpeg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel04.jpeg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel05.jpeg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel06.jpeg" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel07.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel08.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel09.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel10.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel11.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel12.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel13.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>

            <div className="embla__slide">
              <a href="/" id="click-img-form">
                <img src=".././assets/imgs/carroussel/Imovel14.webp" alt="Imagem de Imóveis em Carrossel" />
              </a>
            </div>
          </div>
        </div>
    )
}