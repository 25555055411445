import styled from "styled-components";

export const CookieContent = styled.div`
    width: 100%;
    height: 18vh;
    background-color: #fff;
    text-align: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
    padding: 10vh 10vw;
    border-radius: 2px;
    z-index: 9;
    position: fixed;
    bottom: 1rem;

    .cookie-text {

        display: flex;
        flex-direction: column;
        gap: 0.5vh;

        h2 {
            color: grey;
            font-size: 2vh;
        }

        p {
            font-size: 1.2vh;
            color: black;
        }
    }

    .cookie-btns {

        display: flex;
        justify-content: space-between;
        margin-top: 1vh;
    
        button {
            font-size: 0.6rem;
            border: none;   
            border-radius: 6px;
            cursor: pointer;
        }

        button#more-info {
            padding: 0.4rem;

            a {
                text-decoration: none;
                color: grey;
            }
        }

        button#accept-cookies {
            background-color: green;
            color: white;
            padding: 0.3rem;
            font-weight: 700;

            transform: scale(1);
	        animation: pulse 2s infinite;

            @keyframes pulse {
                0% {
                    transform: scale(0.95);
                }

                70% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(0.95);
                }
            }
    }
    }

    @media (min-width: 768px) {
        .cookie-text {
            h2 {
                font-size: 3vh;
            }
            
            p {
                font-size: 1.7vh;
            }
        }

        .cookie-btns {
            button#accept-cookies {
                padding: 0.5rem;
                font-weight: 700;
                font-size: 0.8vw;
            }
        }
    }
`
export const BackOpacity = styled.div`
    height: 100vh;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    position: fixed;
    z-index: 8;
    top: 0;
    left: 0;
`