import { HeaderBody } from "../components/Header/styles";
import NavBar from "../components/NavBar";
import Privacy from "../components/Privacy";
import GlobalStyle from "../styles/Global.style";

const Priv = () => {
    return(
        <>
        <GlobalStyle/>
        <NavBar/>
        <HeaderBody>
            <Privacy/>  
        </HeaderBody>
        </>
    )
}

export default Priv;